@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
html {
  height: calc(100vh+30px);
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #0e052e;
  /* background: black; */
  /* background: linear-gradient(0deg, #05020f 0%, #0e052e 80%); */
  height: inherit;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-x: hidden;
}
/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: #00000080 #ffffff00;
}
/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: #f1f1f100;
}
*::-webkit-scrollbar-thumb {
  background: #00000080;
  border-radius: 20px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #00000080;
}

@font-face {
  font-family: "Nickainley";
  src: local("Nickainley-Normal"),
    url(/static/media/Nickainley-Normal.5bd352df.otf) format("truetype");
}

@font-face {
  font-family: "RobotoCondensed";
  src: local("RobotoCondensedRegular"),
    url(/static/media/RobotoCondensedRegular.52ee8b59.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

